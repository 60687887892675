<template>
  <section class="py-2">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="title-otherSection">
            <h2>Overview</h2>
            <div v-html="courseData.description"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "otherSection",
  props: {
    courseData: {
      required: true,
      type: [Array, Object],
    }
  },
};
</script>
